import axios from 'axios'
import moment from 'moment'
const NEWS_PAGE = 14;
const INTERVIEWS_PAGE = 8;
const COVERAGES_PAGE = 8;
const OPINIONS_PAGE = 8;
const EVENTS_PAGE = 8;
const FILES_PAGE = 9;
const MEDIAS_PAGE = 11;

function getEnty(object, slug) {
    var entry = null
    Object.keys(object).forEach(key => {
        if (object[key][slug]) {
            entry = object[key][slug];
        }
    });
    return entry;
}

function checkForNewPage(object, page, zone, theme) {
    if (object.lastFilters.zone == zone && object.lastFilters.theme == theme) {
        if (object.items[page] != null && Object.keys(object.items[page]).length > 0) {
            return null;
        }
    } else {
        return -1;
    }
    return page;
}

export default {
    namespaced: true,
    state: {
        themes: [],
        zones: [],
        banners: [],
        bannersFeatures:[],
        news: {
            viewPage: 1,
            lastFilters: {
                zone: null,
                theme: null,
            },
            items: {},
            maxItems: null,
        },
        interviews: {
            viewPage: 1,
            lastFilters: {
                zone: null,
                theme: null
            },
            items: {},
            maxItems: null,
        },
        coverages: {
            viewPage: 1,
            lastFilters: {
                zone: null,
                theme: null
            },
            items: {},
            maxItems: null,
        },
        opinions: {
            viewPage: 1,
            lastFilters: {
                zone: null,
                theme: null
            },
            items: {},
            maxItems: null,
        },
        events: {
            viewPage: 1,
            lastFilters: {
                zone: null,
                theme: null
            },
            items: {},
            maxItems: null,
        },
        others: {
            viewPage: 1,
            lastFilters: {
                zone: null,
                theme: null
            },
            items: {},
            maxItems: null,
        },
        eventsCalendar: {
            items: {},
            lastFilters: {
                zone: null,
                theme: null,
                starDate: null,
                endDate: null
            },
        },
        files: {
            viewPage: 1,
            lastFilters: {
                zone: null,
                theme: null
            },
            items: {},
            maxItems: null,
        },
        medias: {
            viewPage: 1,
            lastFilters: {
                zone: null,
                theme: null
            },
            items: {},
            maxItems: null,
        },
        featuredNews: null,
        featured: null,
        themeSelected: null,
        zoneSelected: null,
        pagesFixed: {},
        loading: false,
    },
    getters: {
        getThemes: (state) => {
            return state.themes;
        },
        getThemeSlug: (state) => (slug) => {
            let entry = null
            if (state.themes) {
                Object.keys(state.themes).forEach(key => {
                    if (state.themes[key].slug == slug) {
                        entry = state.themes[key]
                    }
                });
            }
            return entry;
        },
        getThemesSpecials: (state) => {
            return state.themes.filter(element => {
                return element.special == 1;
            });
        },
        getThemesNormal: (state) => {
            return state.themes.filter(element => {
                return element.special == 0;
            });
        },
        getZones: (state) => {
            return state.zones;
        },
        getBanners: (state) => {
            return state.banners;
        },
        getBannersFeatures: (state) => {
            return state.bannersFeatures;
        },
        getThemeSelected: (state) => {
            return state.themeSelected;
        },

        getZoneSelected: (state) => {
            return state.zoneSelected;
        },
        // OTHERS
        getOthers: (state) => (page) => {
            return state.others.items[page];
        },
        getOther: (state) => (slug) => {
            return getEnty(state.others.items, slug)
        },
        getOthersViewPage: (state) => {
            return state.others.viewPage;
        },
        getOthersLastFiltersTheme: (state) => {
            return state.others.lastFilters.theme;
        },
        getOthersLastFiltersZone: (state) => {
            return state.others.lastFilters.zone;
        },
        getPagesOthers: (state) => (itemsPerPage = NEWS_PAGE) => {
            var pages = Math.ceil(state.others.maxItems / itemsPerPage)
            return pages;
        },
       
        // NEWS
        getNews: (state) => (page) => {
            return state.news.items[page];
        },

        getNew: (state) => (slug) => {
            return getEnty(state.news.items, slug)
        },
        getNewViewPage: (state) => {
            return state.news.viewPage;
        },
        getNewLastFiltersTheme: (state) => {
            return state.news.lastFilters.theme;
        },
        getNewLastFiltersZone: (state) => {
            return state.news.lastFilters.zone;
        },
        getPagesNews: (state) => (itemsPerPage = NEWS_PAGE) => {
            var pages = Math.ceil(state.news.maxItems / itemsPerPage)
            return pages;
        },
        getFeaturedNews: (state) => {
            return state.featuredNews;
        },
        getFeatured: (state) => {
            return state.featured;
        },
        // INTERVIEWS
        getInterviews: (state) => (page) => {
            return state.interviews.items[page];
        },
        getInterview: (state) => (slug) => {
            return getEnty(state.interviews.items, slug)
        },
        getinterviewsViewPage: (state) => {
            return state.interviews.viewPage;
        },
        getinterviewsLastFiltersTheme: (state) => {
            return state.interviews.lastFilters.theme;
        },
        getinterviewsLastFiltersZone: (state) => {
            return state.interviews.lastFilters.zone;
        },
        getPagesInterviews: (state) => (itemsPerPage = INTERVIEWS_PAGE) => {
            var pages = Math.ceil(state.interviews.maxItems / itemsPerPage)
            return pages;
        },

        // OPINIONS
        getOpinions: (state) => (page) => {
            return state.opinions.items[page];
        },
        getOpinion: (state) => (slug) => {
            return getEnty(state.opinions.items, slug)
        },
        getOpinionViewPage: (state) => {
            return state.opinions.viewPage;
        },
        getOpinionLastFiltersTheme: (state) => {
            return state.opinions.lastFilters.theme;
        },
        getOpinionLastFiltersZone: (state) => {
            return state.opinions.lastFilters.zone;
        },
        getPagesOpinion: (state) => (itemsPerPage = OPINIONS_PAGE) => {
            var pages = Math.ceil(state.opinions.maxItems / itemsPerPage)
            return pages;
        },

        // COVERAGES
        getCoverages: (state) => (page) => {
            return state.coverages.items[page];
        },
        getCoverage: (state) => (slug) => {
            return getEnty(state.coverages.items, slug)
        },
        getCoveragesViewPage: (state) => {
            return state.coverages.viewPage;
        },
        getCoveragesLastFiltersTheme: (state) => {
            return state.coverages.lastFilters.theme;
        },
        getCoveragesLastFiltersZone: (state) => {
            return state.coverages.lastFilters.zone;
        },
        getPagesCoverages: (state) => (itemsPerPage = COVERAGES_PAGE) => {
            var pages = Math.ceil(state.coverages.maxItems / itemsPerPage)
            return pages;
        },

        // EVENTS
        getEvents: (state) => (page) => {
            return state.events.items[page];
        },
        getEventCalendar: (state) => {
            return state.eventsCalendar.items
        },
        getEvent: (state) => (slug) => {
            return getEnty(state.events.items, slug)
        },
        getEventsViewPage: (state) => {
            return state.events.viewPage;
        },
        getEventsLastFiltersTheme: (state) => {
            return state.events.lastFilters.theme;
        },
        getEventsLastFiltersZone: (state) => {
            return state.events.lastFilters.zone;
        },
        getPagesEvents: (state) => (itemsPerPage = EVENTS_PAGE) => {
            var pages = Math.ceil(state.events.maxItems / itemsPerPage)
            return pages;
        },

        // FILES
        getFiles: (state) => (page) => {
            return state.files.items[page];
        },
        getFile: (state) => (slug) => {
            return getEnty(state.files.items, slug)
        },
        getFilesViewPage: (state) => {
            return state.files.viewPage;
        },
        getFilesLastFiltersTheme: (state) => {
            return state.files.lastFilters.theme;
        },
        getFilesLastFiltersZone: (state) => {
            return state.files.lastFilters.zone;
        },
        getPagesFiles: (state) => (itemsPerPage = FILES_PAGE) => {
            var pages = Math.ceil(state.files.maxItems / itemsPerPage)
            return pages;
        },
        getFilesDocument: (state) => (page) => {
            let value = []
            if (state.files.items) {
                Object.entries(state.files.items[page]).forEach(element => {
                    if (element[0].file_type == 3) {
                        value.push(element)
                    }
                });
            }
            return value
        },
        getFilesReports: (state) => (page) => {
            let value = []
            if (state.files.items) {
                Object.entries(state.files.items[page]).forEach(element => {
                    if (element[1].file_type == 1) {
                        value.push(element)
                    }

                });
            }
            return value
        },
        getFilesManifests: (state) => (page) => {
            let value = []
            if (state.files.items) {
                Object.entries(state.files.items[page]).forEach(element => {
                    if (element[1].file_type == 2) {
                        value.push(element)
                    }
                });
            }
            return value
        },
        getFilesMagazines: (state) => (page) => {
            let value = []
            if (state.files.items) {
                Object.entries(state.files.items[page]).forEach(element => {
                    if (element[1].file_type == 0) {
                        value.push(element)
                    }
                });
            }
            return value
        },

        // MEDIAS
        getMedias: (state) => (page) => {
            return state.medias.items[page];
        },
        getMedia: (state) => (slug) => {
            return getEnty(state.medias.items, slug)
        },
        getMediasViewPage: (state) => {
            return state.medias.viewPage;
        },
        getMediasLastFiltersTheme: (state) => {
            return state.medias.lastFilters.theme;
        },
        getMediasLastFiltersZone: (state) => {
            return state.medias.lastFilters.zone;
        },
        getPagesMedias: (state) => (itemsPerPage = MEDIAS_PAGE) => {
            var pages = Math.ceil(state.medias.maxItems / itemsPerPage)
            return pages;
        },
        getMediaPhotos: (state) => (page) => {
            let value = []
            if (state.medias.items) {
                Object.entries(state.medias.items[page]).forEach(element => {
                    if (element[1].file_type == 0) {
                        value.push(element)
                    }
                });
            }
            return value
        },
        getMediaVideos: (state) => (page) => {
            let value = []
            if (state.medias.items) {
                Object.entries(state.medias.items[page]).forEach(element => {
                    if (element[1].file_type == 1) {
                        value.push(element)
                    }
                });
            }
            return value
        },
        getMediaPodcast: (state) => (page) => {
            let value = []
            if (state.medias.items) {
                Object.entries(state.medias.items[page]).forEach(element => {
                    if (element[1].file_type == 2) {
                        value.push(element)
                    }
                });
            }
            return value
        },

        // LOADING
        getLoading: (state) => {
            return state.loading;
        },
        // PAGES FIXED
        getPageFixed: (state) => (slug) => {
            return state.pagesFixed[slug]
        },
    },
    actions: {
        loadThemes({ commit, state }) {
            if (state.themes != null && Object.keys(state.themes).length > 0) {
                return;
            }
            return new Promise((resolve, reject) => {
                var promise = axios.post('themes');
                promise.then(function (result) {
                    commit('addThemes', result.data.data);
                    resolve();
                });
            })
        },
        loadZones({ commit, state }) {
            if (state.zones != null && Object.keys(state.zones).length > 0) {
                return;
            }
            return new Promise((resolve, reject) => {
                var promise = axios.post('zones');
                promise.then(function (result) {
                    commit('addZones', result.data.data);
                    resolve();
                });
            })
        },
        loadBanners({ commit, state }) {
            if (state.banners != null && Object.keys(state.banners).length > 0) {
                return;
            }
            return new Promise((resolve, reject) => {
                var promise = axios.get('banners');
                promise.then(function (result) {
                    commit('addBanners', result.data.data);
                    resolve();
                });
            })
        },

        loadEvenTheThingsWithHandtStolen({ commit, dispatch }) {
            dispatch('loadNews');
            dispatch('loadInterviews');
            dispatch('loadCoverages');
            dispatch('loadOpinions');
            dispatch('loadEvents');
            dispatch('loadFiles');
            dispatch('loadMedias');
            dispatch('loadOthers');
        },
        // OTHERS
        loadOthers({ commit, state }, { page = 1, length = NEWS_PAGE } = {}) {
            let zone = state.zoneSelected ? state.zoneSelected.slug : null;
            let theme = state.themeSelected ? state.themeSelected.slug : null;
            page = checkForNewPage(state.others, page, zone, theme);
           
            if (page == -1) {
                page = 1;
                commit('cleanOthers');
            } else if (page === null) {
                return;
            }

            commit('loading');
            let filtersSelect = [
                {
                    name: "themes.slug",
                    search: theme
                },
                {
                    name: "zone.slug",
                    search: zone
                }
            ];
            let data = {
                page: page,
                length: length,
                columns: [
                    {
                        name: 'zone',
                        onlyWith: true
                    },
                    {
                        name: 'themes',
                        onlyWith: true
                    }
                ],
                filters: filtersSelect,
                order: [
                    {
                        name: 'publish_date',
                        direction: 'desc'
                    }
                ],
            }
            return new Promise((resolve, reject) => {
                var promise = axios.post('others', data);
                promise.then(function (result) {
                    commit('addOthers', {
                        others: result.data.data,
                        page: page,
                        maxItems: result.data.recordsFiltered,
                        filters: {
                            zone: zone,
                            theme: theme,
                        }
                    });
                    commit('endLoad')
                    resolve(result);
                });
            })
        },
        loadOther({ commit, state }, {
            slug = 'default'
        } = {}) {
            var entry = getEnty(state.others.items, slug)
            if (entry) {
                return true
            }
            return new Promise((resolve, reject) => {
                axios.get("others/" + slug + '?c-load[]=zone&c-load[]=themes')
                    .then(function (result) {
                        resolve(result.data);
                    });
            });
        },
        // NEWS
        loadNews({ commit, state }, { page = 1, length = NEWS_PAGE } = {}) {
            let zone = state.zoneSelected ? state.zoneSelected.slug : null;
            let theme = state.themeSelected ? state.themeSelected.slug : null;
            page = checkForNewPage(state.news, page, zone, theme);
            if (page == -1) {
                page = 1;
                commit('cleanNews');
            } else if (page === null) {
                return;
            }

            commit('loading');
            let filtersSelect = [
                {
                    name: "themes.slug",
                    search: theme
                },
                {
                    name: "zone.slug",
                    search: zone
                }
            ];
            let data = {
                page: page,
                length: length,
                columns: [
                    {
                        name: 'zone',
                        onlyWith: true
                    },
                    {
                        name: 'themes',
                        onlyWith: true
                    }
                ],
                filters: filtersSelect,
                order: [
                    {
                        name: 'publish_date',
                        direction: 'desc'
                    }
                ],
            }
            return new Promise((resolve, reject) => {
                var promise = axios.post('entries', data);
                promise.then(function (result) {
                    commit('addNews', {
                        news: result.data.data,
                        page: page,
                        maxItems: result.data.recordsFiltered,
                        filters: {
                            zone: zone,
                            theme: theme,
                        }
                    });
                    commit('endLoad')
                    resolve(result);
                });
            })
        },
        loadNew({ commit, state }, {
            slug = 'default'
        } = {}) {
            var entry = getEnty(state.news.items, slug)
            if (entry) {
                return true
            }
            return new Promise((resolve, reject) => {
                axios.get("entries/" + slug + '?c-load[]=zone&c-load[]=themes')
                    .then(function (result) {
                        resolve(result.data);
                    });
            });
        },
        loadPageFixed({ commit, state }, {
            slug = 'default'
        } = {}) {
            if (state.pagesFixed[slug]) {
                return
            }
            return new Promise((resolve, reject) => {
                axios.get("fixed-pages/" + slug)
                    .then(function (result) {
                        resolve(result.data);
                        if (state.pagesFixed[result.data.slug] == null) {
                            state.pagesFixed[result.data.slug] = {}
                        }
                        state.pagesFixed[result.data.slug] = result.data

                    });
            });
        },
        loadFeaturedNews({ commit, state }) {
            if (state.featuredNews !== null) {
                return;
            }
            let data = {
                columns: [
                    {
                        name: 'zone',
                        onlyWith: true
                    },
                    {
                        name: 'themes',
                        onlyWith: true
                    }
                ],
                filters: [
                    // {
                    //     name: 'themes.special',
                    //     search: 1
                    // },
                    {
                        name: 'featured',
                        search: 1
                    }
                ],
                order: [
                    {
                        name: 'publish_date',
                        direction: 'desc'
                    }
                ],

            };
            return new Promise((resolve, reject) => {
                axios.post("entries", data)
                    .then(function (result) {
                        commit('addFeaturedNews', result.data.data);
                        resolve(result.data);
                    });
            });
        },




        loadFeatured({ commit, state }) {
            if (state.featured !== null) {
                return;
            }
            let data = {
                length: 10,
                order: [
                    {
                        name: 'publish_date',
                        direction: 'desc'
                    }
                ],

            };
            return new Promise((resolve, reject) => {
                axios.post("featured", data)
                    .then(function (result) {
                        commit('addFeatured', result.data.data);
                        resolve(result.data);
                    });
            });
        },
        // INTERVIEWS
        loadInterviews({ commit, state }, { page = 1, length = INTERVIEWS_PAGE } = {}) {
            let zone = state.zoneSelected ? state.zoneSelected.slug : null;
            let theme = state.themeSelected ? state.themeSelected.slug : null;
            page = checkForNewPage(state.interviews, page, zone, theme);
            if (page == -1) {
                page = 1;
                commit('cleanInterviews');
            } else if (page === null) {
                return;
            }

            let filtersSelect = [
                {
                    name: "themes.slug",
                    search: theme
                },
                {
                    name: "zone.slug",
                    search: zone
                }
            ];
            let data = {
                page: page,
                length: length,
                columns: [
                    {
                        name: 'zone',
                        onlyWith: true
                    },
                    {
                        name: 'themes',
                        onlyWith: true
                    }
                ],
                filters: filtersSelect,
                order: [
                    {
                        name: 'publish_date',
                        direction: 'desc'
                    }
                ],
            }

            //Todas las acciones en commit
            commit('loading')
            return new Promise((resolve, reject) => {
                var promise = axios.post('interviews', data);
                promise.then(function (result) {
                    commit('addInterviews', {
                        interviews: result.data.data,
                        page: page,
                        maxItems: result.data.recordsFiltered,
                        filters: {
                            zone: zone,
                            theme: theme,
                        }
                    });
                    commit('endLoad')

                    resolve();
                });
            })
        },
        loadInterview({ commit, state }, { slug = 'default' } = {}) {
            var entry = getEnty(state.interviews.items, slug)
            if (entry) {
                return true
            }
            return new Promise((resolve, reject) => {
                axios.get("interviews/" + slug + '?c-load[]=zone&c-load[]=themes')
                    .then(function (result) {
                        resolve(result.data);
                    });
            });
        },

        // COVERAGES
        loadCoverages({ commit, state }, { page = 1, length = COVERAGES_PAGE } = {}) {
            let zone = state.zoneSelected ? state.zoneSelected.slug : null;
            let theme = state.themeSelected ? state.themeSelected.slug : null;
            page = checkForNewPage(state.coverages, page, zone, theme);
            if (page == -1) {
                page = 1;
                commit('cleanCoverages');
            } else if (page === null) {
                return;
            }

            let filtersSelect = [
                {
                    name: "themes.slug",
                    search: theme
                },
                {
                    name: "zone.slug",
                    search: zone
                }
            ];

            let data = {
                page: page,
                length: length,
                columns: [
                    {
                        name: 'zone',
                        onlyWith: true
                    },
                    {
                        name: 'themes',
                        onlyWith: true
                    }
                ],
                filters: filtersSelect,
                order: [
                    {
                        name: 'publish_date',
                        direction: 'desc'
                    }
                ],
            }

            //Todas las acciones en commit
            commit('loading')
            return new Promise((resolve, reject) => {
                var promise = axios.post('coverages', data);
                promise.then(function (result) {
                    commit('addCoverages', {
                        coverages: result.data.data,
                        page: page,
                        maxItems: result.data.recordsFiltered,
                        filters: {
                            zone: zone,
                            theme: theme,
                        }
                    });
                    commit('endLoad')

                    resolve();
                });
            })
        },
        loadCoverage({ commit, state }, { slug = 'default' } = {}) {
            var entry = getEnty(state.coverages.items, slug)
            if (entry) {
                return true
            }
            return new Promise((resolve, reject) => {
                axios.get("coverages/" + slug + '?c-load[]=zone&c-load[]=themes')
                    .then(function (result) {
                        resolve(result.data);
                    });
            });
        },

        // OPINIONS
        loadOpinions({ commit, state }, { page = 1, length = OPINIONS_PAGE } = {}) {
            let zone = state.zoneSelected ? state.zoneSelected.slug : null;
            let theme = state.themeSelected ? state.themeSelected.slug : null;
            page = checkForNewPage(state.opinions, page, zone, theme);
            if (page == -1) {
                page = 1;
                commit('cleanOpinions');
            } else if (page === null) {
                return;
            }
            let filtersSelect = [
                {
                    name: "themes.slug",
                    search: theme
                },
                {
                    name: "zone.slug",
                    search: zone
                }
            ];

            let data = {
                page: page,
                length: length,
                columns: [
                    {
                        name: 'zone',
                        onlyWith: true
                    },
                    {
                        name: 'themes',
                        onlyWith: true
                    }
                ],
                filters: filtersSelect,
                order: [
                    {
                        name: 'publish_date',
                        direction: 'desc'
                    }
                ],
            }

            //Todas las acciones en commit
            commit('loading')
            return new Promise((resolve, reject) => {
                var promise = axios.post('opinions', data);
                promise.then(function (result) {
                    commit('addOpinions', {
                        opinions: result.data.data,
                        page: page,
                        maxItems: result.data.recordsFiltered,
                        filters: {
                            zone: zone,
                            theme: theme
                        }
                    });
                    resolve();
                    commit('endLoad')
                });
            })
        },
        loadOpinion({ commit, state }, { slug = 'default' } = {}) {
            var entry = getEnty(state.opinions.items, slug)
            if (entry) {
                return true
            }
            return new Promise((resolve, reject) => {
                axios.get("opinions/" + slug + '?c-load[]=zone&c-load[]=themes')
                    .then(function (result) {
                        resolve(result.data);
                    });
            });
        },
        // EVENTS
        loadEvents({ commit, state }, { page = 1, length = EVENTS_PAGE } = {}) {
            console.log(moment(new Date()).format('YYYY-MM-DD'))
            let zone = state.zoneSelected ? state.zoneSelected.slug : null;
            let theme = state.themeSelected ? state.themeSelected.slug : null;
            page = checkForNewPage(state.events, page, zone, theme);
            if (page == -1) {
                page = 1;
                commit('cleanEvents');
            } else if (page === null) {
                return;
            }

            let filtersSelect = [
                {
                    name: "themes.slug",
                    search: theme
                },
                {
                    name: "zone.slug",
                    search: zone
                },
                {
                    'name': 'start_date',
                    'search': {
                        'min': moment(new Date()).format('YYYY-MM-DD'),
                        'max': "2065-1-1"

                    }
                }
            ];
            //2022 - 12 - 31

            let data = {
                page: page,
                length: length,
                order: [
                    {
                        name: 'start_date',
                        direction: 'asc'
                    }
                ],
                columns: [
                    {
                        name: 'zone',
                        onlyWith: true
                    },
                    {
                        name: 'themes',
                        onlyWith: true
                    }
                ],
                filters: filtersSelect
            }

            //Todas las acciones en commit
            commit('loading')
            return new Promise((resolve, reject) => {
                var promise = axios.post('events', data);
                promise.then(function (result) {
                    commit('addEvents', {
                        events: result.data.data,
                        page: page,
                        maxItems: result.data.recordsFiltered,
                        filters: {
                            zone: zone,
                            theme: theme,
                        }
                    });
                    resolve();
                    commit('endLoad')
                });
            })
        },
        loadEventsCalendar({ commit, state }, {
            filtersResults = {
                starDate: null,
                endDate: null
            } } = {}) {
            let zone = state.zoneSelected ? state.zoneSelected.slug : null;
            let theme = state.themeSelected ? state.themeSelected.slug : null;
            filtersResults.zone = zone;
            filtersResults.theme = theme;
            if (JSON.stringify(state.eventsCalendar.lastFilters) == JSON.stringify(filtersResults)) {
                if (state.eventsCalendar.items != null && Object.keys(state.eventsCalendar.items).length > 0) {
                    return;
                }
            } else {
                commit('cleanEventsCalendar');
            }

            let filtersSelect = [
                {
                    name: "themes.slug",
                    search: theme
                },
                {
                    name: "zone.slug",
                    search: zone
                }
            ];
            if ((filtersResults.starDate) || (filtersResults.endDate)) {
                filtersSelect.push(
                    {
                        'name': 'start_date',
                        'search': {
                            'min': filtersResults.starDate,
                            'max': filtersResults.endDate
                        }
                    }
                )
            }
            let data = {
                order: [
                    {
                        name: 'start_date',
                        direction: 'desc'
                    }
                ],
                columns: [
                    {
                        name: 'zone',
                        onlyWith: true
                    },
                    {
                        name: 'themes',
                        onlyWith: true
                    }
                ],
                filters: filtersSelect
            }

            //Todas las acciones en commit
            commit('loading')
            return new Promise((resolve, reject) => {
                var promise = axios.post('events', data);
                promise.then(function (result) {
                    commit('addEventsCalendar', {
                        eventsCalendar: result.data.data,
                        filters: {
                            zone: zone,
                            theme: theme,
                            starDate: filtersResults.starDate,
                            endDate: filtersResults.endDate
                        }
                    });
                    resolve();
                    commit('endLoad')
                });
            })
        },

        loadEvent({ commit, state }, { slug = 'default' } = {}) {
            var entry = getEnty(state.events.items, slug)
            if (entry) {
                return true
            }
            return new Promise((resolve, reject) => {
                axios.get("events/" + slug + '?c-load[]=zone&c-load[]=themes')
                    .then(function (result) {
                        resolve(result.data);
                    });
            });
        },
        // FILES
        loadFiles({ commit, state }, { page = 1, length = FILES_PAGE } = {}) {
            let zone = state.zoneSelected ? state.zoneSelected.slug : null;
            let theme = state.themeSelected ? state.themeSelected.slug : null;
            page = checkForNewPage(state.files, page, zone, theme);
            if (page == -1) {
                page = 1;
                commit('cleanFiles');
            } else if (page === null) {
                return;
            }

            let filtersSelect = [
                {
                    name: "themes.slug",
                    search: theme
                },
                {
                    name: "zone.slug",
                    search: zone
                }
            ];
            let data = {
                page: page,
                length: length,
                order: [
                    {
                        name: 'publish_date',
                        direction: 'desc'
                    }
                ],
                columns: [
                    {
                        name: 'zone',
                        onlyWith: true
                    },
                    {
                        name: 'themes',
                        onlyWith: true
                    }
                ],
                filters: filtersSelect,

            }

            //Todas las acciones en commit
            commit('loading')
            return new Promise((resolve, reject) => {
                var promise = axios.post('files', data);
                promise.then(function (result) {
                    commit('addFiles', {
                        files: result.data.data,
                        page: page,
                        maxItems: result.data.recordsFiltered,
                        filters: {
                            zone: zone,
                            theme: theme,
                        }
                    });
                    resolve();
                    commit('endLoad')
                });
            })
        },
        loadFile({ commit, state }, { slug = 'default' } = {}) {
            var entry = getEnty(state.files.items, slug)
            if (entry) {
                return true
            }
            return new Promise((resolve, reject) => {
                axios.get("files/" + slug + '?c-load[]=zone&c-load[]=themes')
                    .then(function (result) {
                        resolve(result.data);
                    });
            });
        },

        // MEDIAS
        loadMedias({ commit, state }, { page = 1, length = MEDIAS_PAGE } = {}) {
            let zone = state.zoneSelected ? state.zoneSelected.slug : null;
            let theme = state.themeSelected ? state.themeSelected.slug : null;
            page = checkForNewPage(state.news, page, zone, theme);
            if (page == -1) {
                page = 1;
                commit('cleanMedias');
            } else if (page === null) {
                return;
            }

            let filtersSelect = [
                {
                    name: "themes.slug",
                    search: theme
                },
                {
                    name: "zone.slug",
                    search: zone
                }
            ];
            let data = {
                page: page,
                length: length,
                order: [
                    {
                        name: 'publish_date',
                        direction: 'desc'
                    }
                ],
                columns: [
                    {
                        name: 'zone',
                        onlyWith: true
                    },
                    {
                        name: 'themes',
                        onlyWith: true
                    }
                ],
                filters: filtersSelect

            }

            //Todas las acciones en commit
            commit('loading')
            return new Promise((resolve, reject) => {
                var promise = axios.post('medias', data);
                promise.then(function (result) {
                    commit('addMedias', {
                        medias: result.data.data,
                        page: page,
                        maxItems: result.data.recordsFiltered,
                        filters: {
                            zone: zone,
                            theme: theme,
                        }
                    });
                    resolve();
                    commit('endLoad')
                });
            })
        },
        loadMedia({ commit, state }, { slug = 'default' } = {}) {
            var entry = getEnty(state.medias.items, slug)
            if (entry) {
                return true
            }
            return new Promise((resolve, reject) => {
                axios.get("medias/" + slug + '?c-load[]=zone&c-load[]=themes')
                    .then(function (result) {
                        resolve(result.data);
                    });
            });
        },
    },
    mutations: {
        //States
        setLoading(state, st) {
            state.loading = st;
        },
        loading(state) {
            state.loading = true;
        },
        endLoad(state) {
            state.loading = false;
        },
        addThemes(state, newData) {
            state.themes = newData;
        },
        addZones(state, newData) {
            state.zones = newData;
        },
        addBanners(state, newData) {
            newData.forEach(element => {
                if (element.featured == 1) {
                    state.bannersFeatures.push(element)
                }
                else{
                    state.banners.push(element);

                }
            })
        },
        // OTHERS
        addOthers(state, { others, filters, page, maxItems }) {
            others.forEach(element => {
                if (state.others.items[page] == null) {
                    state.others.items[page] = {}
                }
                state.others.items[page][element.slug] = element;
            });
            state.others.maxItems = maxItems;
            state.others.lastFilters = filters;
            let todelete = page - 3
            if (todelete > 1) {
                state.others.items[todelete] = {}
            }
        },
        cleanOthers(state) {
            state.others.viewPage = 1
            state.others.items = {}
            state.others.lastFilters = {
                theme: null,
                zone: null
            }
        },
        setPageOthers(state, page) {
            state.others.viewPage = page;
        },
        // NEWS
        addNews(state, { news, filters, page, maxItems }) {
            news.forEach(element => {
                if (state.news.items[page] == null) {
                    state.news.items[page] = {}
                }
                state.news.items[page][element.slug] = element;
            });
            state.news.maxItems = maxItems;
            state.news.lastFilters = filters;
            let todelete = page - 3
            if (todelete > 1) {
                state.news.items[todelete] = {}
            }
        },
        cleanNews(state) {
            state.news.viewPage = 1
            state.news.items = {}
            state.news.lastFilters = {
                theme: null,
                zone: null
            }
        },
        setPageNew(state, page) {
            state.news.viewPage = page
        },
        addFeaturedNews(state, items) {
            state.featuredNews = items;
        },
        addFeatured(state, items) {
            state.featured = items;
        },
        // INTERVIEWS
        addInterviews(state, { interviews, filters, page, maxItems }) {
            if (interviews) {
                interviews.forEach(element => {
                    if (state.interviews.items[page] == null) {
                        state.interviews.items[page] = {}
                    }
                    state.interviews.items[page][element.slug] = element;
                });
            }
            if (maxItems) {

                state.interviews.maxItems = maxItems;
            }
            if (filters) {
                state.interviews.lastFilters = filters;
            }
            if (page) {
                let todelete = page - 3
                if (todelete > 1) {
                    state.interviews.items[todelete] = {}
                }
            }
        },
        cleanInterviews(state) {
            state.interviews.viewPage = 1
            state.interviews.items = {}
            state.interviews.lastFilters = {
                theme: null,
                zone: null
            }
        },
        setPageInterviews(state, page) {
            state.interviews.viewPage = page
        },

        // COVERAGES
        addCoverages(state, { coverages, filters, page, maxItems }) {
            if (coverages) {
                coverages.forEach(element => {
                    if (state.coverages.items[page] == null) {
                        state.coverages.items[page] = {}
                    }
                    state.coverages.items[page][element.slug] = element;
                });
            }
            if (maxItems) {

                state.coverages.maxItems = maxItems;
            }
            if (filters) {
                state.coverages.lastFilters = filters;
            }
            if (page) {
                let todelete = page - 3
                if (todelete > 1) {
                    state.coverages.items[todelete] = {}
                }
            }
        },
        cleanCoverages(state) {
            state.coverages.viewPage = 1
            state.coverages.items = {}
            state.coverages.lastFilters = {
                theme: null,
                zone: null
            }
        },
        setPageCoverages(state, page) {
            state.coverages.viewPage = page
        },

        // OPINIONS
        addOpinions(state, { opinions, filters, page, maxItems }) {
            if (opinions) {
                opinions.forEach(element => {
                    if (state.opinions.items[page] == null) {
                        state.opinions.items[page] = {}
                    }
                    state.opinions.items[page][element.slug] = element;
                });
            }
            if (maxItems) {
                state.opinions.maxItems = maxItems;
            }
            if (filters) {
                state.opinions.lastFilters = filters;
            }
            if (page) {
                let todelete = page - 3
                if (todelete > 1) {
                    state.opinions.items[todelete] = {}
                }
            }
        },
        setPageOpinion(state, page) {
            state.opinions.viewPage = page
        },

        cleanOpinions(state) {
            state.opinions.items = {}
            state.opinions.pages = []
            state.news.lastFilters = {
                theme: null,
                zone: null
            }
        },

        // EVENTS
        addEvents(state, { events, filters, page, maxItems }) {
            if (events) {
                events.forEach(element => {
                    if (state.events.items[page] == null) {
                        state.events.items[page] = {}
                    }
                    state.events.items[page][element.slug] = element;
                });
            }
            if (maxItems) {
                state.events.maxItems = maxItems;
            }
            if (filters) {
                state.events.lastFilters = filters;
            }
            if (page) {
                let todelete = page - 3
                if (todelete > 1) {
                    state.events.items[todelete] = {}
                }
            }
        },
        addEventsCalendar(state, { eventsCalendar, filters }) {

            if (eventsCalendar) {
                //console.log(eventsCalendar)
                state.eventsCalendar.items = eventsCalendar;

            }

            if (filters) {
                state.eventsCalendar.lastFilters = filters;
            }

        },
        cleanEvents(state) {
            state.events.viewPage = 1
            state.events.items = {}
            state.events.lastFilters = {
                theme: null,
                zone: null
            }

        },
        cleanEventsCalendar(state) {
            state.eventsCalendar.items = {}
            state.eventsCalendar.lastFilters = {
                zone: null,
                theme: null,
                starDate: null,
                endDate: null
            }

        },
        setPageEvents(state, page) {
            state.events.viewPage = page
        },

        // FILES
        addFiles(state, { files, filters, page, maxItems }) {
            if (files) {
                files.forEach(element => {
                    if (state.files.items[page] == null) {
                        state.files.items[page] = {}
                    }
                    state.files.items[page][element.slug] = element;
                });
            }
            if (maxItems) {
                state.files.maxItems = maxItems;
            }
            if (filters) {
                state.files.lastFilters = filters;
            }
            if (page) {
                let todelete = page - 3
                if (todelete > 1) {
                    state.files.items[todelete] = {}
                }
            }
        },
        cleanFiles(state) {
            state.files.viewPage = 1
            state.files.items = {}
            state.files.lastFilters = {
                theme: null,
                zone: null
            }

        },
        setPageFiles(state, page) {
            state.files.viewPage = page
        },

        // MEDIAS
        addMedias(state, { medias, filters, page, maxItems }) {
            if (medias) {
                medias.forEach(element => {
                    if (state.medias.items[page] == null) {
                        state.medias.items[page] = {}
                    }
                    state.medias.items[page][element.slug] = element;
                });
            }
            if (maxItems) {
                state.medias.maxItems = maxItems;
            }
            if (filters) {
                state.medias.lastFilters = filters;
            }
            if (page) {
                let todelete = page - 3
                if (todelete > 1) {
                    state.medias.items[todelete] = {}
                }
            }
        },
        cleanMedias(state) {
            state.medias.viewPage = 1
            state.medias.items = {}
            state.medias.lastFilters = {
                theme: null,
                zone: null
            }
        },
        setPageMedias(state, page) {
            state.medias.viewPage = page
        },
        setTheme(state, theme) {
            state.themeSelected = theme;
        },
        setZone(state, zone) {
            state.zoneSelected = zone;
        }
    }
}